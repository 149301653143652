

.services-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.serviceTitle {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #01eca6;
}

.services-image-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px; /* Adjust the gap as needed for larger screens */
}

.service-item {
  text-align: center;
  margin: 10px;
}

.service-item img {
  width: 100%;
  max-width: 75px;
  height: auto;
  border-radius: 10px;
}

.service-item p {
  margin-top: 10px;
  font-size: 1rem;
}

@media (max-width: 600px) {
  .services-container {
    padding: 20px;
    margin-top: 30%;
    height: auto;
  }

  .services-image-container {
    grid-template-columns: repeat(3, 1fr); 
    gap: 10px; 
  }
  .serviceTitle {
    margin-top: 0;
  }
  .service-item {
    margin: 0;
  }
  
  .service-item p {
    margin-top: 0;
  }
}
@media (max-width: 600px) {
  .services-image-container {
    grid-template-columns: repeat(3, 1fr); 
    gap: 0;
  }
  .services-container {
    padding: 20px;
    margin-top: 2px;
  }
}
@media (max-width: 480px) {
  .services-container {
    height: auto;
  }
  .serviceTitle {
    margin-top: 20%;
  }
}