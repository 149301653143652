
body {
  margin: 0;
  padding: 0;
}
.aboutus-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}

.about-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-section {
  text-align: center;
  max-width: 800px;
}

.title-about {
  font-size: 2rem;
  color: #01eca6;
}

.description {
  line-height: 1.2;
}

.nameBig {
  font-size: 2rem;
  color: #333;
}

.mission-section {
  margin-top: 10px;
  text-align: center;
  width: 100%;
  background-color: rgb(195, 33, 72);
  clip-path: circle();
}

.Brands-Image {
  width: 100%;
  max-width: 800px;
}


@media (max-width: 768px) {
  .aboutus-container {
    height: auto;
  }
  .title-about {
   margin-top: 10%;
  }
  .nameBig {
    font-size: 1.5rem;
  }

}

@media (max-width: 480px) {
  .aboutus-container {
    height: auto;
    margin-top: 0 !important;
  }

  .title-about {
    font-size: 2rem;
    margin-top: 0;
    margin-top: 15%;
  }
}


