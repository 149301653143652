.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  
  .logo {
    font-size: 1.5rem;
    max-width: 180px;
    cursor: pointer;
  }
  
  .toggle-button {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    background: none;
    border: none;
    color: black;
    margin-left: 5%;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    cursor: pointer;
  }
  
  .nav-links li {
    margin: 0 1rem;
  }

  .nav-links li a{
    text-decoration: none;
    font-weight: 500;
  }

  .bookNow {
    background-color: #c32148;
    color: white;
    border-radius: 10%;
    border: 2px solid #373737;
  }

  .active-link {
    color: #01eca6;
  }
 
  @media (max-width: 600px) {
    .toggle-button {
      margin-right: 5%;
      display: block;
      position: sticky;
    }
    .navbar {
      justify-content: space-between;
    }
  
    .nav-links {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      align-items: flex-end;
      color: white;
      background-color: rgba(17, 20, 29, 0.8);
      padding: 1rem;
    }
  
    .nav-links li {
      margin: 0.5rem 0;
      justify-content: right;
      margin-right:10%;
    }

    .active-link {
  color: #01eca6 !important;
  }

    
    
    
    .bookNow {
      color: white;
      background-color: #c32148;
      border-radius: 5%;
    }
  }
 
  @media (min-width: 710px) {
    .nav-links {
      display: flex;
    }
  }