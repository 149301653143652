
.footer {
  background-image: url('/public/carMechanic.jpg');
  color: white;
}

.footer-content {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center; 
  width: 100%;
  max-height: 500px;
  background-color: rgba(195, 33, 72, 0.6);
}

.footer-column {
  flex: 1 1 100px; /* Adjusted flex properties for better responsiveness */
  text-align: center;
  padding: 0 10px;
  margin-bottom: 20px;
}

.footer-heading {
  margin-bottom: 10px;
}
  
  .social-links a {
    margin-right: 10px;
  }
  
  .social-links img {
    width: 30px;
    height: 30px;
  }

  .footer-logo img {
    margin-top: 20%;
    margin-left: 10%;
    display: flex;
    align-items: center;
    height: 80px;
    width: auto;
  }

  .location {
    color: white;
  }
  