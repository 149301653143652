.image-slider {
  position: relative;
  width: 100%;
}

.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  overflow: hidden; /* Hide overflow to prevent image stretching */
  max-width: 100%; /* Ensure slider fits within parent */
}

.brands {
  
  border-radius: 5%;
}
.arrow {
  position: absolute;
  top: 50%;
  font-size: 2rem;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
  border-radius: 50%;
  z-index: 2;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.arrow:hover {
  background: rgba(255, 255, 255, 0.8);
}

.brands {
  max-width: 100%; /* Ensure images don't exceed slider container width */
  height: 250px; /* Maintain aspect ratio */
  display: block; /* Remove default inline display */
  margin: 0 auto; /* Center horizontally */
}


@media screen and (max-width: 768px) {
  .arrow {
    font-size: 1.5rem;
    padding: 8px;
  }
}
