  .booking-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }

  .booking-form {
    align-items: center;
    justify-content: center;
    background-color: #373737;
    padding: 20px;
    border-radius: 10px;
    width: 30%; 
    
  }
  .booking-form label{
    color: white;
  }
  
  .booking-form input,
  .booking-form select {
    width: calc(100% - 16px); /* Adjusted input width for smaller screens */
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    margin-bottom: 10px; /* Added margin bottom for better spacing */
    font-size: 14px; /* Adjusted font size for smaller screens */
  }

  .warning {
    color: white;
  }
  
  .title {
    color: white !important;
  }
  
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input,
  select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #01eca6;
  }
  input:focus {
    outline: 1px solid #01eca6;
  }

  .button-space {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .submit-btn {
    background-color: #01eca6;
    width: 100%;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bolder;
  }
  
  .status {
    margin-top: 10px;
    color: maroon;
    font-weight: bold;
  }

  @media (max-width: 900px) {
    .booking-container {
      height: auto;
      margin-top: 0;
    }
    .booking-form {
      margin-top: 10%;
      width: 60%;
     }
  }

  @media (max-width: 599px) {
    .booking-container {
      margin-top: 0;
      width: 100%;
      height: auto;
    }
   .booking-form {
    margin-top: 10%;
    width: 100%;
   }
   }
  
   @media (max-width: 480px) {
    .booking-container {
      width: 100%;
      height: auto;
    }
    .booking-form {
      margin-top: 10%;
      width: 100%;
     }
   }