

.home-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.background-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  filter: blur(5px) grayscale() brightness(70%);
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.centered-text {
  position: absolute;
  font-weight: bolder;
  top: 40%;
  left: 45%;
  transform: translate(-30%, -30%);
  text-align: left;
  color: white;
}

.centered-text h1 {
  margin-bottom: 10px;
  font-size: 4vw;
  font-weight: 900;
}

.centered-text p {
  font-size: 2vw;
}

.footerhome {
  position: absolute;
  background-color: rgba(195, 33, 72, 0.6);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 0;
  bottom: 0;
  height: auto;
}

.icon {
  height: 1.5em;
  width: 1.5em;
  padding-right: 10px;
  padding-top: 10px;
}

.footerhome .footer-section {
  flex: 1;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.footerhome .footer-section p {
  border-top: 20px;
  color: white;
  font-size: small;
}

.footer-section:not(:first-child):not(:last-child) {
  border-left: 1px solid #01eca6;
  border-right: 1px solid #01eca6;
}


@media (max-width: 768px) {
  .footerhome .footer-section {
    text-align: center;
  }
  
  .centered-text {
    top: 30%;
    left: 40%;
    text-align: center;
  }

  .centered-text h1{
    font-size: 6vw;
  }
  .centered-text p{
    font-size: 3vw;
  }
  .footerhome .footer-section p {
    color: white;
    font-size: small;
  }
  .footer-section:not(:first-child):not(:last-child) {
    border-left: 0 ;
    border-right: 0;
  }

 
}

@media (max-width: 480px) {
  .footerhome {
    height: 40%;
  } 
  .footerhome .footer-section {
    flex-basis: 100%;
    height: 15%;
    border-left: 0;
    border-right: 0;
  }
  .centered-text h1{
    font-size: 7vw;
  }
  .centered-text p{
    font-size: 4vw;
  }
}

